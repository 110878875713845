<template>
  <div style="overflow: hidden">
    <control-navbar />
    <br />
    <div class="container">
      <div class="row">
        <div class="col-12">
          <img
            :src="require('../assets/construction.webp')"
            width="30%"
            alt=""
            class=""
          />
        </div>
        <div class="col-12">
          <h5>{{ locale.coming_soon }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ControlNavbar from "../components/ControlNavbar.vue";
import { LocaleArabic, LocaleEnglish } from "../scripts/Locale";

export default {
  name: "ComingSoon",
  components: {
    ControlNavbar,
  },
  data() {
    return {
      locale: null,
    };
  },
  methods: {
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
  },
  created: function () {
    this.checkLocal();
  },
};
</script>

<style lang="scss" scoped>
.whish-tint {
  filter: invert(65%) sepia(67%) saturate(3181%) hue-rotate(330deg)
    brightness(86%) contrast(111%);
}
img {
  margin: 5%;
}
</style>
<style lang="css" scoped>
.pulse {
  animation: pulse 3s infinite;
  margin: 0 auto;
  display: table;
  animation-direction: alternate;
  -webkit-animation: pulse 10s infinite;
  animation-name: pulse 10s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.25);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>

